.float-l {
    float: left;
}

.float-r {
    float: right;
}

.va-midle {
    vertical-align: middle;
}

.text-center {
    text-align:center;
}

.text-right {
    text-align:right;
}

.text-left {
    text-align:left;
}

.clearfix:after {
    display:block;
    clear:both;
    content: "";
}

.text-white {
    color:white;
}

.uppercase {
    text-transform: uppercase;
}

.bold {
    font-family: Gill Sans MT Bold;
}

.text-helper {
    color: var(--main-text-help);
    font-size: .85rem;
}

.d-none {
    display: none;
}

.d-inline {
    display: inline;
}

.box-shadow{
    box-shadow: 0 5px 20px 0 rgb(18 60 116 / 11%);
}

.box-shadow-2 {
    box-shadow: 0 0 10px 0px rgb(18 60 116 / 12%)
}
