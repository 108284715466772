.home-slider {
    width: 50vw;
    margin-top: 2vw;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 4vw;
}


.home-slider-container{
    border: 4px solid rgba(28, 162, 126, 0.9);
}

@media screen and (max-width: 800px)
{
    .home-slider {
        width: 97%;
    }

    .swiper-slide img {
        height: 30vh !important;
    }
}
