input, textarea, select {
    color: black;
    text-align: center;
    outline: none;
    text-align-last:center;
    border: 1px solid var(--main-border-field);
    background: var(--main-white-color) !important;
}

input, select{
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}


input, select {
    height: 57px;

}

input:focus, select:focus {
    border: 2px solid var(--main-yellow-color);
}

input.is-invalid, select.is-invalid, textarea.is-invalid {
    border: 1px solid var(--main-red-color);
}
.invalid-feedback {
    color: var(--main-red-color);
    font-size: .875em;
    margin-top: 0.05rem;
    width: 100%;
    margin-bottom: 0.4rem;
}

.form-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.form-group-two {
    display: flex;
    flex-flow: column nowrap;
    width: 48%;
}

.form-group-one {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 98%;
}

.first-input {
    padding-right: 1vw;
}

.second-input {
}

.site-input {
    display: block;
    height: 40px;
    margin: 1vh 0;
    padding: 5px;
    text-align: left;
    -moz-text-align-last: left;
    text-align-last: left;
    width: 100%;
}

.select-input {
    height: 40px;
    width: 100%;
    margin: 1vh 0;
    padding: 5px;


}

sup {
    top: 0px;
    font-size: 20px;
    color: var(--main-pink-color);
    left: 5px;
}

input[type="password"] {
    font-size: 12px !important;
}
