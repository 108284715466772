section#header {
}

.header-logo {
    display: flex;
    justify-content: center;
    background: var(--main-grey-background-light);
    padding-top: 1vh;
    padding-bottom: 1vh;
}

.header-img-logo {
    width: 16vw;
}

.navigation {
    padding: 1vw;
    display: flex;
    flex-flow: row nowrap;
    list-style-type: none;
    align-items: center;
    justify-content: space-evenly;
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
    background: var(--main-white-color);
    border: 2px solid var(--main-green-color);
}


.header-li {
    padding-left: 10px;
    padding-right: 10px;
    underline: none;
}

.header-li-a-text {
    display: block;
}

.horaire {
    font-size: 1rem;
    text-align: center;
}

.header-a {
    text-decoration:none;
    color: var(--main-green-color);
    font-size: 1.2em;
}

.header-icon {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 3px;
    color: var(--main-green-color);
    display: block;
    margin: auto;
}

.caret-icon {
    padding-left: 10px;
}

nav > ul > li:hover > a, li:hover > a > .header-icon {
    color: var(--main-pink-color);
}

.modal-background {
    background: rgba(211,211,211, 0.5);
    width:100vw;
    height: 300vh;
    position: absolute;
    display: none;
    z-index: 10;
}

.popup-sous-menu {
    position: absolute;
    right: 0;
    background: var(--main-white-color);
    z-index: 10;
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    width: 38vw;
    position:absolute;
    Animation-delay : 0s ;
    Animation-duration : 0.5s ;
    Animation-iteration-count : 1 ;
    Animation-direction: normal;
    Animation-name: from-right-to-left;
}

@-webkit-keyframes from-right-to-left {
    0% {
        width: 0;
    }
    100% {
        width: 38vw;
    }
}

#popup-mon-compte {
    display: none;
}

.popup-sous-menu ul {
    padding-inline-start: 0px !important;
    list-style-type: none;
}

.box-1 {
    background: var(--main-pink-color);
    text-align: center;
    padding: 3vh 0;
}

.box-1 p {
    color: var(--main-white-color);
    font-size: 1.2rem;
}

.box-2 {
    background: var(--main-white-color);
    text-align: center;
}

.box-2 > ul > li {
    padding-top: 2vh;
    padding-bottom: 2vh;
}

.icon-circle-box-1 {
    position: absolute;
    right: 4vh;
    top: 4vh;
    color: var(--main-white-color);
    cursor: pointer;
}

#popup-basket {
    display: none;
}

.popup-basket{
    width: 38vw;
}

.popup-basket-container {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    padding-left: 3vw;
    padding-right: 3vw;

}

.popup-basket-title {
    padding-top: 4vh;
    margin-block-end: 0;
}


.li-basket {
    position: relative;
}

.count-items-basket {
    position: absolute;
    left: 45px;
    top: 5px;
    height: 25px;
    width: 25px;
    background-color: red;
    color: var(--main-white-color);
    border-radius: 50%;
    display: flex; /* or inline-flex */
    align-items: center;
    justify-content: center;
}

.popup-basket-garanties-container {
    border: 1px solid var(--main-divider-color);
    padding: 1vw 2vw 1vw 2vw;
}

.popup-basket-icons-group {
    display: flex;
    flex-direction: row;
}

.popup-basket-icon-color {
    color: var(--main-green-color);
}

.popup-basket-item-title {
    text-decoration: underline;
    text-decoration-color: var(--main-green-color);
    margin-block-start: 0em;
    margin-block-end: 0.5em;
}

.popup-basket-p-garanties {
    font-size: 0.8rem;
    margin-block-start: 0.1rem;
    margin-block-end: 0.1rem;
    padding-left: 1.5vw;
}

.popup-basket-item-added-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 1.5vh;
    width: 100%;
}

.popup-basket-item-image {
    align-self: center;
}

.popup-basket-item-content-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: flex-start;
}

.basket-item-img {
    width: 130px;
    height: 130px;
}

.popup-basket-item-content-intitule p, .popup-basket-item-content-valeur p {
    margin-block-start: 0.3em;
    margin-block-end: 0.3em;
    font-size: 0.8rem;
}


.popup-basket-price {
    color: var(--main-pink-color)
}

.popup-basket-montant-total-container p{
    margin-block-start: 0.1em;
    margin-block-end: 0.3em;
    text-align: center;
}

.p-intitule-montant-total {
    text-decoration: underline;
    font-size: 1.2rem;

}
.p-montant-total {
    color: var(--main-green-color);
    font-size: 1.5rem;
}

.btn-popup-basket {
    border: none;
    height: 45px;
    width: 100%;
    margin-bottom: 1vh;
}

.popup-basket-mb-end {
    margin-bottom: 5vh;
}

.li-a-account-desktop {
    display: block;
}

.li-a-account-mobile {
    display: none;
}

/* écran minuscule */
@media screen and  (max-width: 800px)
{
    .count-items-basket {
        width: 20px;
        top: -3px;
        left: 26px;
        height: 20px;
    }

    .header-img-logo {
        width: 40vw;
        display: block;
        margin: 0 auto;
    }

    .navigation {
        justify-content: space-evenly;
    }

    .header-icon {
        font-size: 2.5rem;
    }

    .header-li-a-text {
        display: none;
    }

    .li-a-account-desktop {
        display: none;
    }

    .li-a-account-mobile {
        display: block;
        font-size: 1rem;
    }

    .popup-sous-menu {
        width: 100vw;
    }

    @-webkit-keyframes from-right-to-left {
        0% {
            width: 0;
        }
        100% {
            width: 100vw;
        }
    }

    .popup-basket-item-content {
        margin-left: 10px;
    }
}












