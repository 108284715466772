section#description-home{
    background: var(--main-grey-background-light);
}

.description-home-container {
    width: 60vw;
    margin: 0 auto;
}

.description-container {
    padding-bottom: 4vw;
}

.description-container p{
    text-align: center;
    color: var(--main-green-color);
    margin-block-start: 0rem !important;
    margin-block-end: 0.8rem !important;
}

.description-container p:not(.description-sous-titre) {
    font-size:  1.25rem;
}

.description-sous-titre {
    font-size: 1.6rem;
    padding-bottom: 2vw !important;
}

.info-description-container {
    border: 2px solid var(--main-yellow-color);
    padding: 0.5vw;

}

.description-title {
    font-size: 2rem;
    padding-top: 5vh;
    text-align: center;
    color: var(--main-pink-color);
    text-transform: uppercase;
    margin-block-end: 2rem;
}

.boite-1 {
    background: var(--main-green-color);
    height: 280px;
    left: 70%;
    opacity: 0.4;
    position: relative;
    width: 42%;
}

.boite-2 {
    bottom: 22vh;
    position: relative;
}

.boite-margin-reset{
    margin-top: -20vh;
}

@media screen and (max-width: 800px) {
    .description-home-container {
        width: 90vw;
    }

    .boite-1 {
        position: static;
        display: none;
    }

    .boite-2 {
        position: static;
        padding-bottom: 2vh;
    }

    .boite-margin-reset {
        margin-top: 0vh;
    }
}
