section#footer {
    background: var(--main-green-color);
    padding-bottom: 0.1px;
}

.footer-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding-top: 3vh;
}

.footer-logo {
    background: white;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 200px;
    height: 180px;
    padding: 5px;
}

.footer-a-link {
    display: block;
    text-decoration: none;
    color: var(--main-white-color);
    padding: 5px 0 0 5px;
}

.footer-title {
    color: var(--main-white-color);
    padding-top: 2vh;
}


.footer-bloc-reseaux {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.footer-a {
    padding-left : 1vw;
    padding-right : 1vw;
}

.footer-icon {
    color: var(--main-white-color);
}

.footer-copyright-p {
    color: white;
    font-weight: bold;
    font-size: 1.2em;
    padding: 5vh 0 0vh 0;
}

.footer-contact-p {
    color: var(--main-white-color);
}

.footer-tel-a {
    color: white;
}
