section#rechercher {
    background: var(--main-green-color);
    padding: 3vh 1vh 4vh 1vh;
}

.rechercher-margin {
    margin: 0 auto;
    width: 80vw;
}

.rechercher-form-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 15px;
    border: 4px solid var(--main-pink-color);
    background: var(--main-grey-background-light);
}

.rechercher-tabs-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 30px;
}

.rechercher-date, .rechercher-periode, .btn-rechercher {
    margin-right: -2px !important;
    width: 25vw;
}

.btn-rechercher {
    height: 57px;
    border-color: var(--main-yellow-color);
    border: none;
    margin-bottom: 4px;
    color: var(--main-white-color);

}

.tabs-bureau, .tabs-salle-reunion {
    background: white;
    cursor: pointer;
    width: 150px;
    text-align: center;
    border-style: solid;
    border-color: var(--main-yellow-color);
}

.tabs-bureau {
    border-width: 4px 4px 4px 4px;
}

.tabs-salle-reunion {
    border-width: 4px 4px 4px 0px;
}

.rechercher-p-tabs-bureau, .rechercher-p-tabs-sr {
    color: var(--main-yellow-color);
}

.rechercher-date, .rechercher-periode {
    border: 4px solid var(--main-yellow-color);
}

.tabs-active {
    background: var(--main-yellow-color);
}

.p-tabs-active {
    color: var(--main-white-color);
}

.rechercher-dp {
    position: relative;
    z-index: 1;
}

.icon-rechercher {
    position: absolute;
    color: var(--main-pink-color);
    left: 12px;
    top: -1px;
}

.rechercher-p-helptext-duree {
    color: var(--main-yellow-color);
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    margin-block-end: 0;
}

@media screen and  (max-width: 800px)
{
    .rechercher-dp, .periodeBureau {
        display: block !important;
        width: 100%;
    }

     .rechercher-date, .rechercher-periode {
        margin-right: -2px!important;
        width: 70vw;
        margin-bottom: 10px;
    }

    .btn-rechercher {
        width: 100%;
    }

    .icon-rechercher {
        top: 16px;
    }
}

