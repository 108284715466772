section#coworking-results {
    background: var(--main-white-color);
}

.cr-container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    margin-top: 2vw;
    margin-bottom: 2vw;
}

.cr-card {
    margin: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 28%;
}

.cr-content {
    margin-top: 1vw;
    text-align: center;
}

.cr-photo-img {
    width: 15vw;
    height: 30vh;
}

.crc-title {
    color: var(--main-green-color);
    margin-block-end: -6px !important;
}

.crc-a-title {
    text-decoration: none;
}

.crc-a-title:hover h2 {
    color: var(--main-pink-color)
}

.crc-icons-text {
    display: inline-block;
    font-size: 0.9rem;
    padding-right: 5px;
}

.crc-horaire-price {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.crc-horaire {
    font-size: 0.85rem;
    color: var(--main-text-help);
}

.crc-price {
    color: var(--main-pink-color);
    font-size: 1.5rem;
}

.crc-icons {
    color: var(--main-yellow-color);
    padding-left: 4px;
    padding-right: 4px;
}

.btn-ajouter-panier {
    height: 45px;
    width: 100%;
    border: none;
}

.loading-icons {
    text-align: center;
    margin-top: 5vh;
}

.cr-p-no-result {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--main-green-color);

}
@media screen and  (max-width: 850px)
{
    .cr-photo-img {
        width: 40vw;
        height: 25vh;
    }
    .cr-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .cr-card {
        width: 80vw;
        align-self: center;
    }
}

@media screen and (min-width: 800px) and (max-width: 1250px)
{
    .popup-basket-item-content {
        margin-left: 20px;
    }
}



