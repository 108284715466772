.btn {
    display: inline-flex;
    justify-content: center; /* center the content horizontally */
    align-items: center; /* center the content vertically */
    text-decoration: none;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 1.2rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    white-space: normal;
    word-wrap: break-word;
    font-weight: bold;
    /*text-transform: uppercase;*/
    line-height: 1em;
    vertical-align: middle;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.btn-green {
    background: var(--main-green-color);
    color: white;
}

.btn-green:hover {
    background-color: rgba(28, 162, 126, .8);
}

.btn-white {
    background: white;
    color: var(--main-green-color);
}

.btn-white:hover {
    background-color: var(--main-green-color);
    color: white;
}

.btn-yellow {
    background: var(--main-yellow-color);
    color: black;
}

.btn-yellow:hover {
    background-color: var(--main-yellow-color-hover);
}

.btn-pink {
    background: var(--main-pink-color);
    color: white;
}

.btn-pink:hover {
    background-color: var(--main-pink-color-hover);
}

.btn-red {
    background: var(--main-red-color);
    color: white;
}

.btn-red:hover {
    background-color: var(--main-red-color-hover);
}

.btn-degrader {
    background: linear-gradient(90deg, var(--main-pink-color), var(--main-yellow-color), var(--main-green-color));
    color: white;

}





