html {
    font-size : 20px;
}

body {
    font-family: Gill Sans MT Light;
    min-height: 100vh;
    width: 100% !important;
    overflow-x: hidden;
}

h1 {
    font-family: Gill Sans MT Bold;
    color: var(--main-green-color);
    font-size: 1.8rem;
    font-weight: normal;
    margin-block-start: 0em;
}

h2 {
     font-family: Gill Sans MT Bold;
     color: var(--main-green-color);
     font-size: 1.4rem;
     font-weight: normal;
     margin-block-start: 0em;
}

h3 {
    font-family: Gill Sans MT Bold;
    font-size: 1.2rem;
    color: var(--main-green-color);
    margin-block-start: 0em;
}

h4 {
    color: var(--main-green-color);
}

p {
    color: var(--main-p-color);
    font-size: 1rem;
}

a {
    color: #58585a;
}

.title {
    padding-top: 3rem;
}

.li-a-account-desktop {
    display: none;
}

/* Swal */
.custom-loader-color {
    border-color: var(--main-pink-color) transparent var(--main-pink-color) transparent !important;
}

/* écran minuscule */
@media screen and  (max-width: 576px)
{
    html {
        font-size : 16px !important;
    }
}

/* très petit écran */
@media screen and (min-width: 576px) and (max-width: 720px)
{
    html {
        font-size : 16px !important;
    }
}
/* petit écran  */
@media screen and (min-width: 720px) and (max-width: 960px)
{
    html {
        font-size : 18px !important;
    }
}
/* écran médium  */
@media screen and (min-width: 960px) and (max-width: 1140px)
{
}
/* grand écran  */
@media screen and (min-width: 1140px)
{
}

