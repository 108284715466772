:root {
    --main-green-color: rgb(28, 162, 126);
    --main-pink-color: rgb(204, 102, 153);
    --main-yellow-color: rgb(255, 204, 51);
    --main-white-color: rgb(255, 255, 255);
    --main-red-color: rgb(255, 0, 0);
    --main-p-color: rgb(51, 51, 51);
    --main-green-color-hover: rgba(51, 153, 102, 0.8);
    --main-yellow-color-hover: rgba(255, 204, 51, 0.8);
    --main-pink-color-hover: rgba(204, 102, 153, 0.8);
    --main-red-color-hover: rgba(255, 0, 0, 0.8);
    --main-text-help: rgb(107, 107, 107);
    --main-divider-color: rgb(235, 235, 235);
    --main-divider-color-2: rgb(221, 221, 221);
    --main-grey-background: #f5f5f5;
    --main-grey-background-light: #f8f8f8;
    --main-grey-text: #9b9a9a;
    --main-blue-color: #0066c0;
    --main-border-field: #A1A1A1FF;
}
